<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-table
        ref="materialTable"
        title="사용부서 목록"
        tableId="materialTable"
        :columns="grid.columns"
        :data="grid.data"
        :columnSetting="false"
        @rowClick="rowClick"
        selection="multiple"
        rowKey="col1"
        :filtering="false"
        :usePaging="false"
        :hideBottom="true"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="LBLADD" icon="add" @btnClicked="addrow" />
            <c-btn label="LBLREMOVE" icon="remove" />
            <c-btn v-if="editable" label="LBLSAVE" icon="save" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chem-components',
  props: {
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '부서코드',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'col3',
            field: 'col3',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.getDetail();
    },
    getDetail() {
      this.grid.data = [
        {
          col1: '1사업장',
          col2: 'DEPT0001',
          col3: '사용부서1',
        },
        {
          col1: '1사업장',
          col2: 'DEPT0002',
          col3: '사용부서2',
        },
      ];
    },
    reset() {
    },
    addrow() {
      // 초기에는 userId에 tempId를 부여
      this.grid.data.splice(0, 0, {
        col1: '',
        col2: '',
        col3: '',
        col4: '',
        col5: '',
        col6: '',
        col7: 'N',
        useFlag: 'Y',
      })
    },
  }
};
</script>